function removeEmoji(text) {
  
  return String(text).replace(/[\u{1F600}-\u{1F64F}]|[\u{1F300}-\u{1F5FF}]|[\u{1F680}-\u{1F6FF}]|[\u{2600}-\u{26FF}]|[\u{2700}-\u{27BF}]|\uD83C[\uDE01-\uDEFF]|\uD83D[\uDC00-\uDE4F]|\uD83E[\uDD10-\uDDFF]/gu, '');
}

import React, {useState, useEffect, useRef} from 'react'
import './AutoMenuNavigator.scss'
export default function AutoMenuNavigator ({subMenus}) {
  const [expanded, setExpanded] = useState(false)
  const [currentCategoryId, setCurrentCategoryId] = useState('')
  const observerRef = useRef(null)
  function clickLink (catId) {
    observerRef.current.disconnect()
    const yOffset= -180
    const titleEle = document.getElementById(`menu-category-${catId}`)
    const y = titleEle.getBoundingClientRect().top + window.pageYOffset + yOffset;
    window.scrollTo({top: y, behavior: 'smooth'});
    observeNav()
    
  }
  function observeNav () {
    const ids = subMenus.filter(({tag}) => {
      if (tag && (tag.indexOf('hide') !== -1)) {
       return false
      }
      return true
    }).map(({id}) => `#menu-category-${id}`)
    observerRef.current = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          const elementId = entry.target.id
          // console.log('in view', elementId, entry.target.innerHTML)
          setCurrentCategoryId(elementId)
          // check if 
          document.querySelector(`#top-nav-${elementId}`).scrollIntoViewIfNeeded({ behavior: "smooth", inline: "center" });

        }
      })
    }, {
      rootMargin: "0px 0px -400px 0px"
    })
    ids.forEach(id => {
      observerRef.current.observe(document.querySelector(id))
    })
    return () => { observerRef.current.disconnect() }
    
  }
  useEffect(observeNav, [0])
  return(
    <div className="menu-nav-wrapper">
      <nav
        className="menu-navigation"
        role="navigation"
        aria-label="Menu Categories">
        {subMenus.filter(({tag}) => {
              if (tag && (tag.indexOf('hide') !== -1)) {
               return false
              }
              return true
            })
          .map(({id, name, description, disableOrder}) => {
            const isActiveStyle = (`menu-category-${id}` === currentCategoryId) ? " active" : ""
            return(
              <a
                id={`top-nav-menu-category-${id}`}
                onClick={(e) => {
                  e.preventDefault()
                    clickLink(id)
                }}
                className={"category-link-item" + isActiveStyle}
                href="">{removeEmoji(name && name.en)}</a>
            )
        })}
      </nav>
    </div>
  )
}
